import axios from '@/http/axios'


export function getClosedInvoices (page, filters = [], sorts = []) {
  let queries = ''
  if (page > 1) {
    queries += `page=${page}`
  }

  if (filters.length) {
    if (queries.length > 0) queries += '&'

    queries += filters.join('&')
  }

  if (sorts.length) {
    if (queries.length > 0) queries += '&'

    queries += sorts.join('&')
  }

  if (queries.length > 0) queries = `?${  queries}`

  return axios({
    url: `v1/admin/closed-invoices${queries}`,
    method: 'get'
  })
}

export function getClosedInvoice (id) {
  return axios({
    url: `v1/admin/closed-invoices/${id}`,
    method: 'get'
  })
}

export function editClosedInvoice (id, payment) {
  return axios.patch(`v1/admin/closed-invoices/${id}`, payment)
}

export function insertClosedInvoice (payment) {
  return axios({
    url: 'v1/admin/closed-invoices',
    data: payment,
    method: 'post'
  })
}

export function deleteClosedInvoice (id, force = false) {
  return axios({
    url: `v1/admin/closed-invoices/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function restoreClosedInvoice (id) {
  return axios({
    url: `v1/admin/closed-invoices/${id}/restore`,
    method: 'put'
  })
}

export function getClosedInvoiceTrash (page, filters = [], sorts = []) {
  let queries = ''
  if (page > 1) {
    queries += `page=${page}`
  }

  if (filters.length) {
    if (queries.length > 0) queries += '&'

    queries += filters.join('&')
  }

  if (sorts.length) {
    if (queries.length > 0) queries += '&'

    queries += sorts.join('&')
  }

  if (queries.length > 0) queries = `&${  queries}`
  return axios({
    url: `v1/admin/closed-invoices?trashed=true${queries}`,
    method: 'get'
  })
}
